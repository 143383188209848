import {graphql, useStaticQuery } from 'gatsby'

export default function useAllNoticias() {
    const resultado = useStaticQuery(graphql`
        query {
            allContentfulNoticias(sort: {fields: fechaNoticia, order: DESC}) {
            nodes {
                id
                titulo
                slug
                stack
                desc {
                    desc
                }
                fechaNoticia(formatString: "DD-MMMM-YYYY", locale: "es")
                imagen {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO,PNG,WEBP])
                }
            }
            }
        } 
        `);
    return resultado.allContentfulNoticias.nodes;
}


import React, {useState, useEffect } from 'react'
import useAllNoticias from '../components/Hooks/useAllNoticias';
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function CardNoticia() {
    const listadoNoticias = useAllNoticias();
    const [noticias, guardarNoticias] = useState([]);
    useEffect(() => {
        guardarNoticias(listadoNoticias);
    }, [])
    return (
        <div className="blogGrid">

        
        {noticias.map(noticia=>{
            const { id,titulo, slug, desc, fechaNoticia} = noticia

            const image = getImage(noticia.imagen.gatsbyImageData)
            return (

                    <div class="card">
                        <div class="card-header">
                            <GatsbyImage
                                image={image}
                                alt={titulo}
                                className="img"
                            ></GatsbyImage>
                        </div>
                        <div class="card-body">
                            <div className="tags-container">
                                    {
                                    noticia.stack.map((tag,index)=>{ 
                                        return (
                                            <span 
                                                key={index} 
                                                className="tagsNoticias"
                                            >
                                                {tag}
                                            </span>
                                        )   
                                    }) 
                                    }
                            </div>
                            <h4>{titulo}</h4>
                            <p>{desc.desc}</p>
                        </div>
                        <div className='flex justify-center m-4 pr-8 w-full'> 
                                <Link 
                                    to={`/${slug}`} 
                                    className=" smtBtm cursor-pointer text-sm rounded px-4 py-2 text-white 
                                                uppercase font-bold mb-2"    
                                >
                                Leer Más...
                                </Link>
                        </div>              
                    </div>
               
            )
        })} 
        </div>       
    )
}

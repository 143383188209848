import React from 'react'
import Layout from '../components/Layout'
import CardNoticia from '../components/CardNoticia'
import Seo from '../components/Seo'
import '../assets/css/cardsstyle.css'
export default function blognoticias() {
    return (
        <div>
            <Layout>
            <Seo title="Noticias" />
                <div className="flex flex-col justify-center mt-8">
                    <h2 className="text-3xl md:text-5xl col-span-2 font-black py-2 
                        uppercase self-center tituloNot">todas las Noticias</h2>
                    <CardNoticia />

                </div>
               
            </Layout>
        </div>
    )
}
